




























import Vue from 'vue';
import Sider from './sider.vue';
import Header from './header.vue';

export default Vue.extend({
  components: {
    Sider,
    Header,
  },
  data() {
    return {
      collapsed: false,
    };
  },
  methods: {
    changeCollapse(val: boolean) {
      this.collapsed = val;
    },
  },
});
