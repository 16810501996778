













































import Vue from 'vue';
import { mapGetters } from 'vuex';
import SubMenu from './subMenu.vue';

export default Vue.extend({
  components: {
    SubMenu,
  },
  props: {
    collapsed: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      selectedKeys: [this.$route.path],
      openKeys: this.$route.meta?.subMenuKey,
    };
  },
  watch: {
    '$route.path'(val) {
      if (val === '/ysf/userHandbook') return;
      this.selectedKeys = [val];
      this.openKeys = this.$route.meta?.subMenuKey;
    },
  },
  methods: {
    itemClick({ key }: { key: string }) {
      if (key === '/ysf/userHandbook') {
        window.open(
          'https://ysf.oss.yunsuanfang.com/publics/operation_manual/%E4%BA%91%E7%AE%97%E6%88%BF%E5%B9%B3%E5%8F%B0%E7%94%A8%E6%88%B7%E6%93%8D%E4%BD%9C%E6%89%8B%E5%86%8C.pdf',
          '_black'
        );
        if (this.$route.path !== '/ysf/video') {
          this.$router.push({ path: '/ysf/video' });
        } else {
          this.selectedKeys = ['/ysf/video'];
        }
        return;
      }
      if (key === this.$route.path) return;
      this.$router.push({ path: key });
    },
    toHome() {
      this.$router.push({ path: '/' });
    },
  },
  computed: {
    ...mapGetters(['menus']),
  },
});
