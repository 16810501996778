





















import Vue from 'vue';
export default Vue.extend({
  name: 'customDrawer',
  props: {},
  data() {
    return {};
  },
  methods: {
    getContainer() {
      return document.getElementById('ysf_body');
    },
  },
});
