/*
 * @Description: axios request
 * @Author: zhangguoliang
 * @Date: 2021-08-03 16:11:11
 * @LastEditors: zhangguoliang
 * @LastEditTime: 2023-02-27 10:40:39
 */

import axios from 'axios';
import store from '@/store';
import router from '@/router';
import { message as Message } from 'ant-design-vue';
const request = axios.create({
  baseURL: window.__POWERED_BY_QIANKUN__ ? '/ysfApi' : '/',
});
request.interceptors.request.use(
  (config) => {
    const { url } = config;
    const time = Date.now();
    const { token = '' } = store.getters;
    const { headers } = config;
    if (token && !headers.Authorization) {
      headers.Authorization = `Bearer ${token}`;
    }
    const configs = {
      ...config,
      url: url?.indexOf('?') === -1 ? `${url}?v=${time}` : `${url}&v=${time}`,
      headers: {
        ...headers,
        PRODUCT_CODE: headers.PRODUCT_CODE || 'HZJT_YZJ_WEB',
        AGENCY_CODE: 'HZJT',
        // userId: token || '',
      },
    };
    return configs;
  },
  (err) => Promise.reject(err)
);

request.interceptors.response.use(
  (res) => {
    const { data } = res;
    if (data?.code === 10080) {
      history.pushState(null, '', '/removeMiddleware');
      return Promise.reject();
    }
    if (typeof data === 'object' && data.status && data.status >= 400) {
      return errorHandler(data);
    }
    return data;
  },
  (err) => {
    const { status, data } = err.response || {};
    // if (axios.isCancel(err)) {
    //   return Promise.reject(err.message);
    // } else {
    errorHandler({ status, message: data?.message });
    // }
  }
);

function errorHandler({ status = 200, message = '网络请求错误' }) {
  Message.error(
    status === 401 ? '此账号登录已被强制注销，请重新登录！' : message,
    2
  ).then(
    () => {
      if (status === 401) {
        store.dispatch('logout', 2);
      }
      if (status === 10080) {
        history.pushState(null, '', '/removeMiddleware');
      }
    },
    (err) => {
      console.log(err);
    }
  );
  return Promise.reject(message);
}

export default request.request;
