/*
 * @Descripttion:
 * @Author: sunchen
 * @Date: 2021-10-19 13:47:52
 * @LastEditors: liuxia
 * @LastEditTime: 2021-11-16 10:38:41
 */
import http from '@/utils/http';

//  登录
export function user_login(formdata: any): any {
  return http.post(`/cocc-mtds-resource/1/user/login`, formdata);
}

//  获取所有的字典值
export function sys_dict(): any {
  return http.get(`/cocc-sds-resource/1/sys/dict`);
}

// 通过父级编码查找区划列表
export function project_getQuotaRatesParentByParentCode(
  parentCode: string
): any {
  return http.get(
    `/cocc-sds-resource/1/project/quota-rates/parent/${parentCode}`
  );
}

//  根据城市ID获取三类人工费
export function project_getLabourcostByCity(cityId: string): any {
  return http.get(`/cocc-sds-resource/1/project/labourcostes/${cityId}`);
}

//  获取默认销项税率
export function project_getDefQuotaRates(): any {
  return http.get(`/cocc-sds-resource/1/project/quota-rates/default`);
}
//  根据纳税地区code获取税率
export function project_getQuotaRatesByCode(value: string): any {
  return http.get(`/cocc-sds-resource/1/project/quota-rates/${value}`);
}

//  根据纳税地区code以及计税方式获取税率
export function constructProject_sjRate(area: string,taxMethods): any {
  return http.get(`/cocc-sds-resource/1/constructproject/sjRate/${area}/${taxMethods}`);
}


// 修改密码
export function updatePassword(value: any): any {
  return http.post('cocc-mtds-resource/1/user/updatePassword', value);
}

// 查询登录次数
export function loginCount(): any {
  return http.get('cocc-mtds-resource/1/user/count/login');
}
