/*
 * @Descripttion:
 * @Author: renmingming
 * @Date: 2021-10-18 10:09:13
 * @LastEditors: liuxia
 * @LastEditTime: 2022-10-27 14:25:39
 */
import http from '@/utils/http';
import request from '@/utils/request';
import {
  ProjectDetailType,
  ProjectTree,
  TotalPriceItem,
  SummaryItem,
  ReportTreeItem,
  SingleSummaryType,
  GetZjCountType,
  projectSpaceDetailType,
  mergeInitCheckType,
} from '@/interface/product';
import { BaseResponse } from '@/interface';

import axios from 'axios';

/**
 * 项目详情
 * @param id
 * @returns
 */
export function coccMtdsResource_1_constructProject(id: string) {
  return http.get<ProjectDetailType>(
    `/cocc-mtds-resource/1/construct/project/${id}`
  );
}

/**
 * 左侧数数据
 * @param sequenceNbr
 * @returns
 */
export function coccMtdsResource_1_project_constructsBySequenceNbrTree(
  sequenceNbr: string
) {
  return http.get<ProjectTree[]>(
    `cocc-mtds-resource/1/project/constructs/${sequenceNbr}/tree
`
  );
}

/**
 * 工程项目总价表
 * @param sequenceNbr
 * @returns
 */
export function coccMtdsResource_1_project_constructsBySequenceNbrSummary(
  sequenceNbr: string
) {
  return http.get<TotalPriceItem[]>(
    `cocc-mtds-resource/1/project/constructs/${sequenceNbr}/summary`
  );
}

/**
 * 单项工程费汇总
 * @param sequenceNbr
 * @returns
 */
export function coccMtdsResource_1_project_singlesBySequenceNbrSummary(
  sequenceNbr: string
) {
  return http.get<SummaryItem[]>(
    `/cocc-mtds-resource/1/project/singles/${sequenceNbr}/summary`
  );
}

/**
 * 报表树
 * @param sequenceNbr
 * @returns
 */
export function coccMtdsResource_1_project_constructsBySequenceNbrReportTree(
  sequenceNbr: string
): Promise<any> {
  return request({
    url: `/cocc-mtds-resource/1/project/constructs/${sequenceNbr}/report-tree`,
    method: 'get',
  });
}

/**
 * 人材机汇总页面数据
 * @param params
 * @returns
 */
export function rcjTotal(params): Promise<any> {
  return request({
    url: '/cocc-mtds-resource/1/projectView/rcjTotal',
    method: 'post',
    data: params,
  });
}

/**
 * 查询工程下甲供明细
 * @param constructId
 * @returns
 */
export function employerSuppliedItem(constructId: string): Promise<any> {
  return request({
    url: `/cocc-mtds-resource/1/employer-supplied-items/construct/${constructId}`,
    method: 'get',
  });
}

/**
 * 查询工程下暂估明细
 * @param constructId
 * @returns
 */
export function estimationByConstructId(constructId: string): Promise<any> {
  return request({
    url: `/cocc-mtds-resource/1/zb-estimation/estimation/${constructId}`,
    method: 'get',
  });
}

/**
 * 人材机汇总确认并重新组价
 * @param params
 * @returns
 */
export function confirmAndReassemblePrice(params): Promise<any> {
  return request({
    url: '/cocc-mtds-resource/1/projectView/confirmAndReassemblePrice',
    method: 'post',
    data: params,
  });
}

/**
 * 报表
 * @param params
 * @returns
 */
export function coccMtdsResource_1_project_branchs_categoryByxmTypeReport<Type>(
  params: Type
): Promise<Blob> {
  return request({
    url: `/cocc-mtds-resource/1/project/branchs/category/report`,
    method: 'get',
    responseType: 'blob',
    params: {
      ...params,
    },
  });
}

/**
 * 更新设备费及税金
 * @param params
 * @param sequenceNbr
 * @param sbf
 * @returns
 */
export function coccMtdsResource_1_project_singlesBySequenceNbrSbf<Type>(
  sequenceNbr: string,
  sbf: number,
  params
) {
  return http.post<boolean>(
    `/cocc-mtds-resource/1/project/singles/${sequenceNbr}/sbf/${sbf}`,
    params
  );
}

/**
 * 重新调价
 * @param sequenceNbr
 * @returns
 */
export function coccMtdsResource_1_project_adjustment(
  sequenceNbr,
  params
): Promise<BaseResponse<string>> {
  return request({
    url: `/cocc-mtds-resource/1/project/constructs/${sequenceNbr}/adjustment
`,
    params,
    method: 'post',
  });
}
/**
 * 投标清单导出
 * @param params
 * @returns
 */
export function coccMtdsResource_1_project_constructsByConstructSequenceNbrBidDetail<
  Type
>(constructSequenceNbr: string, params: Type): Promise<ArrayBuffer> {
  return request({
    url: `/cocc-mtds-resource/1/project/constructs/${constructSequenceNbr}/biddetail`,
    method: 'post',
    data: params,
  });
}

/**
 * 单位工程费汇总
 * @param sequenceNbr
 * @returns
 */
export function coccMtdsResource_1_project_unitsBySequenceNbrSummary(
  sequenceNbr: string
) {
  return http.get<SingleSummaryType>(
    `/cocc-mtds-resource/1/project/units/${sequenceNbr}/summary`
  );
}

// 分部项目明细查看
export function coccMtdsResource_1_project_branchsCategory(
  xmType: string,
  params: any
): any {
  return http.get(
    `/cocc-mtds-resource/1/project/branchs/category/${xmType}`,
    params
  );
}
// 人材机增值税表明细
export function getCjszzs(sequenceNbr: string): any {
  return http.get(`/cocc-mtds-resource/1/projectView/cjszzs/${sequenceNbr}`);
}
// 主材表信息明细
export function getZcbInfo(sequenceNbr: string): any {
  return http.get(`/cocc-mtds-resource/1/projectView/zcb/${sequenceNbr}`);
}

// 单位分部项目查询
export function coccMtdsResource_1_project_unitsCategory(params: any): any {
  return http.get(`/cocc-mtds-resource/1/project/units/category`, params);
}

/**
 * 重新计价
 * @param id 投标单位id
 * @returns
 */
export function coccMtdsResource_1_cocc_builder_constructMergeRepeat(
  id: string
): Promise<BaseResponse<string>> {
  return request({
    url: `/cocc-mtds-resource/1/cocc/builder/construct/merge/repeat/${id}`,
    method: 'get',
  });
}

// 近似定额匹配
export function coccMtdsResource_1_project_matchDe(params: any): any {
  return http.post('/cocc-mtds-resource/1/project/match/de', params);
}

// 分部分项替换定额
export function coccMtdsResource_1_project_repeatFbDe(params: any): any {
  return request({
    url: `/cocc-mtds-resource/1/project/repeat/fb-de`,
    method: 'post',
    data: params,
  });
}

// 单价措施替换定额
export function coccMtdsResource_1_project_repeatMeasureDe(params: any): any {
  return request({
    url: `/cocc-mtds-resource/1/project/repeat/measure-de`,
    method: 'post',
    data: params,
  });
}

/**
 * 组价分析
 * @param sequenceNbr
 * @returns
 */
export function coccMtdsResource_1_cocc_builder_constructZj(
  sequenceNbr: string
) {
  return http.get<GetZjCountType>(
    `/cocc-mtds-resource/1/cocc/builder/construct/zj/${sequenceNbr}`
  );
}

// 获取项目是否正在组价
export function coccSpsServer_1_construct_status_merge(
  sequenceNbr: string
): any {
  return http.get(`/cocc-sps-server/1/construct/status/merge/${sequenceNbr}`);
}

/**
 * 人工调价状态
 * @param sequenceNbr
 * @returns
 */
export function coccSpsServer_1_construct_status_price(sequenceNbr: string) {
  return http.get<boolean>(
    `/cocc-sps-server/1/construct/status/price/${sequenceNbr}`
  );
}

/**
 * 重新计价状态
 * @param sequenceNbr
 * @returns
 */
export function coccSpsServer_1_construct_status_again(sequenceNbr: string) {
  return http.get<boolean>(
    `/cocc-sps-server/1/construct/status/again/${sequenceNbr}`
  );
}

/**
 * 获取项目列表所有在组价中的状态
 */
export function coccSpsServer_1_construct_status_mergeRecordNow() {
  return http.get<boolean>(
    `/cocc-sps-server/1/construct/status/mergeRecordNow`
  );
}

/**
 * 停止一键组价
 * @param sequenceNbr
 */
export function coccSpsServer_1_coccBuilder_stopToSequenceNbr(
  sequenceNbr: string
) {
  return http.get<boolean>(
    `/cocc-mtds-resource/1/coccbuilder/stop/${sequenceNbr}`
  );
}

/**
 * 用户是否阅读过操作指引
 * @returns
 */
export function userOperationFirst() {
  return http.get<boolean>('/cocc-mtds-resource/1/user/user-operation/first');
}

/**
 * 获取组价用时时间
 */
export function combinedPriceTime(sequenceNbr) {
  return http.get<{ usedTime: string }>(
    `/cocc-mtds-resource/1/cocc/builder/construct/merge/time/${sequenceNbr}`
  );
}

/**
 * 用户已阅读过操作指引
 * @returns
 */
export function userOperationConfirm() {
  return http.get<string>('/cocc-mtds-resource/1/user/user-operation/confirm');
}

/**
 * 根据投标ID获取重新计价进度
 * @returns
 */
export function againMergeStatusByConstructId(constructId: string) {
  return http.get<string>(
    `/cocc-sps-server/1/construct/status/again/data/${constructId}`
  );
}

/**
 * 根据招标ID删除组价进度
 * @returns
 */
export function deleteMergeStatus(constructId: string) {
  return http.delete<string>(
    `/cocc-mtds-resource/1/coccbuilder/construct/mergeStatus/${constructId}`
  );
}

/**
 * 根据投标ID删除重新计价进度
 * @returns
 */
export function deleteAgainMergeStatus(constructId: string) {
  return http.delete<string>(
    `/cocc-mtds-resource/1/coccbuilder/construct/againMergeStatus/${constructId}`
  );
}

/**
 * 投标id获取清单生成状态
 * @param constructId
 * @returns
 */
export function coccSpsServer_1_construct_status_excel(constructId: string) {
  return http.get<number>(
    `/cocc-sps-server/1/construct/status/excel/${constructId}`
  );
}

/**
 * 是否有定额替换
 * @param constructId
 * @returns
 */
export function coccSpsServer_1_construct_status_replace(constructId: string) {
  return http.get(`/cocc-sps-server/1/construct/status/replace/${constructId}`);
}
/**
 * 投标id删除清单生成状态
 * @param constructId
 * @returns
 */
export function delDetaileStatus(constructId: string) {
  return http.delete(
    `/cocc-mtds-resource/1/cocc/builder/construct/delDetaileStatus/${constructId}`
  );
}

/**
 * 组价清单筛选列表
 * @returns
 */
export function unitItemList(params: any) {
  return request({
    url: `/cocc-mtds-resource/1/constructProject/unit/itemList`,
    method: 'get',
    params,
  });
}

/**
 * 组价推荐查询定额列表
 * @returns
 */
export function unitDeList(data: any) {
  return request({
    url: '/cocc-mtds-resource/1/constructProject/unit/deList',
    method: 'post',
    data,
  });
}

/**
 * 查询相同清单项列表
 * @returns
 */
export function unitSameList(data: any) {
  return request({
    url: '/cocc-mtds-resource/1/constructProject/unit/sameList',
    method: 'post',
    data,
  });
}

/**
 * 批量替换定额
 * @returns
 */
export function replaceDeList(data: any) {
  return request({
    url: '/cocc-mtds-resource/1/constructProject/replace/deList',
    method: 'post',
    data,
  });
}

/**
 * 获取所有状态
 * @param sequenceNbr
 * @returns
 */
export function coccSpsServer_1_construct_status_all(sequenceNbr: string) {
  return http.get<any>(
    `/cocc-sps-server/1/construct/status/all/${sequenceNbr}`
  );
}

/**
 * 获取项目空间详情
 * @param sequenceNbr
 * @returns
 */
export function coccMtdsResource_1_projectSpace(sequenceNbr: string) {
  return http.get<projectSpaceDetailType>(
    `/cocc-mtds-resource/1/projectSpace/${sequenceNbr}`
  );
}

/**
 * 组价前校验清单项
 * @param constructId
 * @returns
 */
export function mergeInitCheck(constructId: string, page = 1, limit = 15) {
  return request({
    url: '/cocc-mtds-resource/1/cocc/builder/construct/merge/init/check',
    method: 'post',
    data: {
      constructId,
      page,
      limit,
    },
  });
}

/**
 * 校验失败取消组价接口清状态中心状态
 * @param sequenceNbr
 * @returns
 */
export function mergeClear(sequenceNbr: string) {
  return request({
    url: `cocc-mtds-resource/1/coccbuilder/clear/${sequenceNbr}`,
    method: 'get',
  });
}

/**
 * 检测是否有更改设备费，强制生成清单
 * @param constructId
 * @returns
 */
export function constructProject_constructFile(constructId: string) {
  return http.get<boolean>(
    `/cocc-mtds-resource/1/constructProject/constructFile/${constructId}`
  );
}

/**
 * 招标通项目导出
 * @param sequenceNbr
 * @returns
 */
export function downloadConstructZip(sequenceNbr: string) {
  return http.get<number>(
    `/cocc-mtds-resource/1/zbtProjectView/downloadConstructZip/${sequenceNbr}`
  );
}

/**
 * 招标通项目
 * @param sequenceNbr 公告ID
 * @returns
 */
export function zbtProjectViewMerge(sequenceNbr) {
  return request({
    url: `/cocc-mtds-resource/1/zbtProjectView/merge`,
    data: sequenceNbr,
    method: 'post',
  });
}

/**
 * 招标通项目
 * @param sequenceNbr 公告ID
 * @returns
 */
export function previewBySequenceNbr(sequenceNbr) {
  return request({
    url: `/cocc-mtds-resource/1/zbtProjectView/preview/${sequenceNbr}`,
    method: 'get',
  });
}

/**
 * 修改清单名称
 * @returns
 */
export function constructModifyName(params) {
  return request({
    url: `/cocc-mtds-resource/1/construct/editor/construct/modify/name`,
    method: 'post',
    data: params,
  });
}

/**
 * 获取企业的code码
 * @returns
 */
export function getAgencyCode() {
  return request({
    url: `/cocc-mtds-resource/1/user/agency-code`,
    method: 'get',
  });
}

/**
 * 重置(恢复)高亮颜色-清单
 * @returns
 */
export function resetQdColor(qdSequenceNbr, params) {
  return request({
    url: `/cocc-mtds-resource/1/projectView/reset/highlight/color/qd/${qdSequenceNbr}`,
    method: 'post',
    data: params,
  });
}

// 查询工程项目人材机汇总并返回整体是否有缓存数据
export function rcjTotalAndCache(params): Promise<any> {
  return request({
    url: '/cocc-mtds-resource/1/projectView/rcjTotalAndCache',
    method: 'post',
    data: params,
  });
}
// 删除人材机整体的临时缓存
export function delRcjCache(params): Promise<any> {
  return request({
    url: '/cocc-mtds-resource/1/projectView/delRcjCache',
    method: 'post',
    data: params,
  });
}
// 保存人材机某一条数据的临时缓存
export function saveRcjCache(params): Promise<any> {
  return request({
    url: '/cocc-mtds-resource/1/projectView/saveRcjCache',
    method: 'post',
    data: params,
  });
}

// 通过城市编码获取人工费以及oss地址
export function applicationNameByCityCode(cityCode: string): Promise<any> {
  return request({
    url: `/cocc-sds-resource/1/project/applicationName/${cityCode}`,
    method: 'get',
  });
}
