<!--
 * @Descripttion: 
 * @Author: sunchen
 * @Date: 2021-10-08 16:41:11
 * @LastEditors: sunchen
 * @LastEditTime: 2021-10-08 18:07:39
-->
<template>
  <component
    :is="formVisible === undefined ? 'div' : 'a-modal'"
    v-model="formVisible"
    class="common-dialog"
    :afterClose="resetForm"
    @ok="submitForm"
  >
    <a-form-model
      v-bind="$attrs"
      v-on="$listeners"
      :model="formState"
      ref="formRef"
    >
      <a-row key="item">
        <a-col
          v-for="(item, index) in columns"
          :key="index"
          :span="item.span || 12"
        >
          <a-form-model-item
            v-bind="item.itemConfig"
            :key="item.itemConfig.prop"
            v-if="!item.hidden"
          >
            <slot v-if="item.slot" :name="item.itemConfig.prop"></slot>
            <component
              v-else
              :is="componentsMap[item.type]"
              v-model="formState[item.itemConfig.prop]"
              v-bind="item.innerConfig"
              v-on="
                item.innerConfig && item.innerConfig.events
                  ? item.innerConfig.events
                  : {}
              "
            >
              <template
                v-if="item.innerConfig && item.innerConfig.slot"
                v-slot:[item.innerConfig.slot]
              >
                <slot
                  :name="item.itemConfig.prop + '_' + item.innerConfig.slot"
                ></slot>
              </template>
            </component>
          </a-form-model-item>
        </a-col>
      </a-row>
    </a-form-model>
  </component>
</template>

<script>
import Vue from 'vue';
const componentsMap = {
  input: 'a-input',
  radio: 'a-radio-group',
  select: 'a-select',
  switch: 'a-switch',
  checkbox: 'a-checkbox-group',
  date: 'a-date-picker',
  textarea: 'a-textarea',
  password: 'a-input-password',
  upload: 'a-upload',
  number: 'a-input-number',
};
const valueTypeMap = {
  switch: 'checked',
  upload: 'file-list',
};

export default Vue.extend({
  name: 'commonForm',
  props: {
    config: {
      type: Array,
      default: () => [],
    },
    value: {
      type: Object,
      default: () => ({}),
    },
    visible: {
      type: Boolean,
      default: undefined,
    },
  },
  data() {
    return {
      componentsMap,
      formRef: null,
    };
  },
  mounted() {
    this.formRef = this.$refs['formRef'];
  },
  computed: {
    columns() {
      return this.config.map((cfg) => ({
        ...cfg,
        valueType: cfg.valueType || valueTypeMap[cfg.type] || 'value',
      }));
    },
    formState: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('update:value', val);
      },
    },
    formVisible: {
      get() {
        return this.visible;
      },
      set(val) {
        this.$emit('update:visible', val);
      },
    },
  },
  methods: {
    submitForm() {
      return new Promise((resolve, reject) => {
        this.formRef.validate((valid) => {
          if (!valid) return;
          resolve({ ...this.formState });
        });
      });
    },

    resetForm() {
      this.formRef.resetFields();
      this.$emit('resetForm');
    },
  },
});
</script>
