/*
 * @Description:
 * @Author: zhangguoliang
 * @Date: 2021-08-02 17:09:13
 * @LastEditors: liuxia
 * @LastEditTime: 2022-06-15 17:55:42
 */
import Vue from 'vue';
import Vuex from 'vuex';
import user from './user';
import common from './common';
import project from './project';
import costQuotaLibrary from './costQuotaLibrary';
import RootState from './interface';

Vue.use(Vuex);

export default new Vuex.Store<RootState>({
  modules: {
    user,
    common,
    project,
    costQuotaLibrary,
  },
});
