/*
 * @Descripttion: 工程项目相关store
 * @Author: renmingming
 * @Date: 2021-10-14 10:08:16
 * @LastEditors: liuxia
 * @LastEditTime: 2022-09-21 17:42:59
 */
import { Module } from 'vuex';
import RootState, { ProjectState } from './interface';
import { RefreshTreeFrom } from '@/interface/product';
import { accAdd } from '@/utils';
import {
  coccMtdsResource_1_constructProject,
  coccSpsServer_1_construct_status_again,
  coccSpsServer_1_construct_status_price,
  coccSpsServer_1_construct_status_excel,
  coccSpsServer_1_construct_status_replace,
  coccSpsServer_1_construct_status_all,
  constructProject_constructFile,
  coccMtdsResource_1_projectSpace,
} from '@/api/projectDetail';
import router from '@/router';
import { message } from 'ant-design-vue';
import Vue from 'vue';

const project: Module<ProjectState, RootState> = {
  state: {
    relevancy: '',
    refreshTreeFrom: '', //是否刷新左侧树
    currentTreeInfo: null,
    isUnfold: true, // 招标模块 是否显示'展开'字样
    isTenderUnfold: true, // 投标模块 是否显示'展开'字样
    isRetractUnfold: true, // 组价模块 是否显示'展开'字样
    combinedPriceStatus: false, // true 组价中 false 一键组价
    projectBidSequenceNbr: '', // 工程投标id
    projectDetail: null, //工程详情
    isPriceAdjustmentOrValuation: false, // 是否在计价
    isPriceAdjustment: false, // 是否在调价
    isProjectlack: false, // 是否是完整的项目
    isThereReplace: false, // 是否有替换项
    tenderRelevancy: '', // 投标文件点击
    projectSequenceNbr: '', // 工程项目sequenceNbr
    toSeeReportForms: false, // 调价成功是否查看报表
    generateListStatus: 0, // 生成清单状态 0未生成1生成中2已生成
    checkedValue: '', // 清单筛选是否有值
    disabledCheckbox: false, // 清单筛选项是否可点击
    selectStatus: false, // 是否从组价分析跳转投标模块
    projectSpaceDetail: null, // 项目空间详情
    isExportMinimality: false, // 导出是否最小化
    exportProcess: 0, // 导出进度
    isExportGenerateList: false, // 是否导出清单生成中
    readOnly: false, // 是否为可读模式,点任何按钮都是请注册登录弹框
    loginVisible: false, // 是否打开登录注册弹框
    currentExportList: [], // 当前正在导出的工程
    combinedSuccessVisible: false, // 一键组价完成是否弹框组价分析模块
    artificialSummary: false, // 点击header人材机汇总跳转对应页面
    isStartCombined: false, // 是否触发一键组价操作
  },
  mutations: {
    addCurrentExportList(state, id) {
      if (!state.currentExportList.includes(id)) {
        state.currentExportList.push(id);
      }
    },
    removeCurrentExportList(state, id) {
      const index = state.currentExportList.indexOf(id);
      if (index >= 0) {
        state.currentExportList.splice(index, 1);
      }
    },
    SET_PROJECT_ISLACK(state, type: boolean) {
      state.isProjectlack = type;
    },
    SET_RELEVANCY(state, name: string) {
      state.relevancy = name;
    },
    SET_RefreshTree(state, val: RefreshTreeFrom) {
      state.refreshTreeFrom = val;
    },
    SET_CURRENT_TREE_INFO(state, treeInfo: ProjectState['currentTreeInfo']) {
      state.currentTreeInfo = JSON.parse(JSON.stringify(treeInfo));
    },
    SET_ISUNFOLD(state, status: boolean) {
      state.isUnfold = status;
    },
    SET_ISTENDERUNFOLD(state, status: boolean) {
      state.isTenderUnfold = status;
    },
    SET_ISRETRACTUNFOLD(state, status: boolean) {
      state.isRetractUnfold = status;
    },
    SET_COMBINEDPRICESTATUS(state, status: boolean) {
      state.combinedPriceStatus = status;
    },
    SET_PROJECT_BIDSEQUENCENBR(state, val: string) {
      state.projectBidSequenceNbr = val;
    },
    /**
     * 当工程详情销毁时重置相关信息
     * @param state
     */
    RESET_RELEVANT_INFO(state) {
      state.projectBidSequenceNbr = '';
      state.currentTreeInfo = null;
    },
    SET_PROJECT_DETAIL(state, info) {
      state.projectDetail = info;
    },
    SET_PRICE_ADJUSTMENT_VALUATION(state, val) {
      state.isPriceAdjustmentOrValuation = val;
    },
    SET_PRICE_ADJUSTMENT(state, val) {
      state.isPriceAdjustment = val;
    },
    SET_IS_THERE_REPLACE(state, val) {
      state.isThereReplace = val;
    },
    SET_TENDERRELEVANCY(state, name: string) {
      state.tenderRelevancy = name;
    },
    SET_PROJECTSEQUENCENBR(state, sequenceNbr: string) {
      state.projectSequenceNbr = sequenceNbr;
    },
    SET_TOSEEREPORTFORMS(state, val: boolean) {
      state.toSeeReportForms = val;
    },
    SET_GENERATELISTSTATUS(state, val: number) {
      state.generateListStatus = val;
    },
    SET_CHECKEDVALUE(state, val) {
      state.checkedValue = val;
    },
    SET_SELECTSTATUS(state, val) {
      state.selectStatus = val;
    },
    SET_DISABLED_CHECKBOX(state, val) {
      state.disabledCheckbox = val;
    },
    SET_PROJECT_SPACE_DETAIL(state, info) {
      state.projectSpaceDetail = info;
    },
    SET_EXPORT_NUMBER(state, num) {
      if (!state.projectSpaceDetail) return;
      state.projectSpaceDetail.exportNumber = num;
    },
    SET_IS_EXPORT_MINIMALITY(state, val: boolean) {
      state.isExportMinimality = val;
    },
    SET_EXPORT_PROCESS(state, val: number) {
      state.exportProcess = val;
    },
    SET_IS_EXPORT_GENERATE_LIST(state, val: boolean) {
      state.isExportGenerateList = val;
    },
    SET_READONLY(state, val) {
      state.readOnly = val;
    },
    SET_LOGINVISIBLE(state, val) {
      state.loginVisible = val;
    },
    /**
     * 初始导出进度相关的状态值
     * @param state
     */
    EXPORT_PROCESS_STATUS_INIT(state) {
      state.isExportMinimality = false;
      state.isExportGenerateList = false;
      state.exportProcess = -1;
      setTimeout(() => {
        state.exportProcess = 0;
      }, 10);
    },
    SET_COMBINED_SUCCESS_VISIBLE(state, val: boolean) {
      state.combinedSuccessVisible = val;
    },
    SET_ARTIFICIAL_SUMMARY(state, val: boolean) {
      state.artificialSummary = val;
    },
    SET_IS_START_COMBINED(state, val: boolean) {
      state.isStartCombined = val;
    },
  },
  actions: {
    /**
     * 工程详情
     */
    getProjectDetail({ state, commit }) {
      const id =
        (router.currentRoute.query.sequenceNbr as string) ||
        state.projectSequenceNbr;
      const sequenceNbr = state.projectBidSequenceNbr || id;
      coccMtdsResource_1_constructProject(sequenceNbr).then((res) => {
        if (res.status === 200) {
          const { constructName, total, extend1, sbf, ssCity } = res.result;
          commit('SET_PROJECT_DETAIL', {
            constructName,
            total,
            extend1,
            sbf,
            ssCity,
          });
        }
      });
    },
    /**
     * 项目空间详情
     * @returns
     */
    getProjectSpace({ commit }) {
      const projectSpaceId = router.currentRoute.query.spaceId;
      if (!projectSpaceId) return;
      coccMtdsResource_1_projectSpace(projectSpaceId as string).then((res) => {
        if (!res.result) return;
        const { importNumber, exportNumber, projectSpaceStatus } = res.result;
        commit('SET_PROJECT_SPACE_DETAIL', {
          importNumber,
          exportNumber,
          projectSpaceStatus,
        });
      });
    },
    /**
     * 工程操作状态查询初始
     * @param param
     */
    projectOperationStatusInit({ state, dispatch, commit }) {
      const sequenceNbr =
        (router.currentRoute.query.sequenceNbr as string) ||
        state.projectSequenceNbr;
      coccSpsServer_1_construct_status_all(sequenceNbr).then((res) => {
        if (!res.result || res.status !== 200) return;
        const { againMerge, adjustPrice, excelPdf, replaceDe, ifExport } =
          res.result;
        commit('SET_PRICE_ADJUSTMENT_VALUATION', againMerge);
        commit('SET_PRICE_ADJUSTMENT', adjustPrice);
        commit('SET_IS_THERE_REPLACE', !!replaceDe);
        commit('SET_GENERATELISTSTATUS', excelPdf);
        commit('SET_IS_EXPORT_GENERATE_LIST', ifExport);
        if (
          (!againMerge && state.isPriceAdjustmentOrValuation) ||
          (!adjustPrice && state.isPriceAdjustment)
        ) {
          commit('SET_RefreshTree', RefreshTreeFrom.ADJUSTMENT_MERGE_SUCCESS);
          dispatch('getProjectDetail');
          dispatch('getGenerateListStatus');
        }
        if (!againMerge && state.isPriceAdjustmentOrValuation) {
          message.success('组价完成');
        }
        if (!adjustPrice && state.isPriceAdjustment) {
          message.success('调价完成');
        }
        if (adjustPrice) {
          dispatch('priceAdjustmentStatus');
        }
        if (excelPdf === 1) {
          dispatch('getGenerateListStatus');
        }
      });
    },
    /**
     * 重新计价状态处理
     */
    async priceOrAgainStatus({ state, dispatch, commit }) {
      const sequenceNbr =
        (router.currentRoute.query.sequenceNbr as string) ||
        state.projectSequenceNbr;
      const res = await coccSpsServer_1_construct_status_again(sequenceNbr);
      console.log('重新计价状态：', res);
      if (!res.result && state.isPriceAdjustmentOrValuation) {
        commit('SET_RefreshTree', RefreshTreeFrom.ADJUSTMENT_MERGE_SUCCESS);
        dispatch('getProjectDetail');
        dispatch('getGenerateListStatus');
        message.success('组价完成');
        Vue.prototype.$websocket.commit('CLEAR_TASK');
        Vue.prototype.$websocket.commit('WEBSOCKET_CLEAR');
      }
      commit('SET_PRICE_ADJUSTMENT_VALUATION', res.result);
      if (res.result) {
        setTimeout(() => {
          dispatch('priceOrAgainStatus');
        }, 5000);
      }
    },
    /**
     * 人工调价状态处理
     */
    async priceAdjustmentStatus({ state, dispatch, commit }) {
      const sequenceNbr =
        (router.currentRoute.query.sequenceNbr as string) ||
        state.projectSequenceNbr;
      const res = await coccSpsServer_1_construct_status_price(sequenceNbr);
      console.log('人工调价状态：', res);
      if (!res.result && state.isPriceAdjustment) {
        commit('SET_RefreshTree', RefreshTreeFrom.ADJUSTMENT_MERGE_SUCCESS);
        dispatch('getProjectDetail');
        message.success('调价完成');
        dispatch('getGenerateListStatus');
      }
      commit('SET_PRICE_ADJUSTMENT', res.result);
      if (res.result) {
        setTimeout(() => {
          dispatch('priceAdjustmentStatus');
        }, 5000);
      }
    },

    /**
     * 是否有定额替换
     * @param param
     */
    projectReplace({ state, commit }) {
      const sequenceNbr =
        (router.currentRoute.query.sequenceNbr as string) ||
        state.projectSequenceNbr;
      coccSpsServer_1_construct_status_replace(sequenceNbr).then((res) => {
        console.log('是否有定额替换：', res);
        commit('SET_IS_THERE_REPLACE', !!res.result);
      });
    },
    /**
     * 获取生成清单状态，组价成功之后调用
     */
    getGenerateListStatus({ state, dispatch, commit }) {
      const sequenceNbr =
        (router.currentRoute.query.sequenceNbr as string) ||
        state.projectSequenceNbr;
      if (!sequenceNbr) return;
      coccSpsServer_1_construct_status_excel(sequenceNbr).then((res) => {
        if (res.status !== 200) return false;
        console.log('清单生成状态：', res);
        const status = res.result;
        commit('SET_GENERATELISTSTATUS', status);
        if (status === 1) {
          setTimeout(() => {
            dispatch('getGenerateListStatus');
          }, 5000);
        } else {
          if (state.isExportGenerateList) {
            commit('SET_IS_EXPORT_GENERATE_LIST', false);
          }
        }
      });
    },
    async syncTriggerGenerationPdf({ getters, dispatch, commit }) {
      const bidSequenceNbr = getters.bidSequenceNbr;
      if (!bidSequenceNbr) return;
      const { status, result } = await constructProject_constructFile(
        bidSequenceNbr
      );
      if (status === 200 && !result) {
        commit('SET_GENERATELISTSTATUS', 0);
        dispatch('getGenerateListStatus');
      }
    },
  },
  getters: {
    isProjectlack(state) {
      return state.isProjectlack;
    },
    /**
     * 当前树节点集合信息
     * @param state
     * @returns
     */
    currentTreeInfo(state) {
      return state.currentTreeInfo;
    },
    projectType(state, getters) {
      return getters.currentTreeInfo?.type;
    },
    sequenceNbr(state, getters) {
      return getters.currentTreeInfo?.sequenceNbr;
    },
    /**
     * 是否工程
     * @param state
     * @returns boolean
     */
    isProject(state, getters) {
      return getters.projectType === '01';
    },
    /**
     * 是否单项工程
     * @param state
     * @returns boolean
     */
    isSingleProject(state, getters) {
      return getters.projectType === '02';
    },
    /**
     * 是否单位工程
     * @param state
     * @returns boolean
     */
    isUnitProject(state, getters) {
      return getters.projectType === '03';
    },
    /**
     * 点击招标模块code关联投标模块文件
     * @param state
     */
    relevancy(state) {
      return state.relevancy;
    },
    /**
     * @description: 获取是否刷新详情，树列表
     * @param {*} state
     * @return {*}
     */
    refreshTreeFrom(state) {
      return state.refreshTreeFrom;
    },
    /**
     * 投标number
     * @param state
     */
    bidSequenceNbr(state, getters) {
      return getters.currentTreeInfo?.bidSequenceNbr;
    },
    /**
     * 是否空白项目
     * @param state
     * @param getters
     * @returns
     */
    isBlankProject(state, getters) {
      if (!getters.currentTreeInfo) return true;
      return getters.currentTreeInfo.isBlank;
    },
    /**
     * 招标模块是否展开
     * @param state
     * @returns
     */
    isUnfold(state) {
      return state.isUnfold;
    },
    /**
     * 投标模块是否展开
     * @param state
     * @returns
     */
    isTenderUnfold(state) {
      return state.isTenderUnfold;
    },
    /**
     * 组价模块是否展开
     * @param state
     * @returns
     */
    isRetractUnfold(state) {
      return state.isRetractUnfold;
    },
    /**
     *
     */
    combinedPriceStatus(state) {
      return state.combinedPriceStatus;
    },
    /**
     * 工程投标id
     * @param state
     * @returns
     */
    projectBidSequenceNbr(state) {
      return state.projectBidSequenceNbr;
    },

    /**
     * 项目详情
     * @param state
     * @returns
     */
    projectDetail(state) {
      return state.projectDetail;
    },
    /**
     * 项目是否设置过组价策略
     * @param state
     * @param getters
     * @returns
     */
    isSetup(state, getters) {
      return Number(getters.projectDetail?.extend1);
    },

    /**
     * 项目名称
     * @param state
     * @param getters
     * @returns
     */
    projectName(state, getters) {
      return getters.projectDetail?.constructName;
    },

    /**
     * 投标总价
     * @param state
     * @param getters
     * @returns
     */
    tenderTotal(state, getters) {
      const total = getters.projectDetail?.total || 0;
      return Number(total);
    },
    /**
     * 是否计价中
     * @param state
     * @returns
     */
    isPriceAdjustmentOrValuation(state) {
      return state.isPriceAdjustmentOrValuation;
    },
    /**
     * 是否调价中
     * @param state
     * @returns
     */
    isPriceAdjustment(state) {
      return state.isPriceAdjustment;
    },
    /**
     * 是否有替换项
     * @param state
     * @returns
     */
    isThereReplace(state) {
      return state.isThereReplace;
    },
    /**
     * 点击投标模块文件
     * @param state
     */
    tenderRelevancy(state) {
      return state.tenderRelevancy;
    },
    /**
     * 存储项目点击详情项目sequenceNbr
     * @param state
     */
    projectSequenceNbr(state) {
      return state.projectSequenceNbr;
    },
    toSeeReportForms(state) {
      return state.toSeeReportForms;
    },
    /**
     *清单正在生成
     * @param state
     * @returns
     */
    listGenerating(state) {
      return state.generateListStatus === 1;
    },
    /**
     * 清单生成成功
     * @param state
     * @returns
     */
    listGenerateSuccess(state) {
      return state.generateListStatus === 2;
    },
    /**
     * 清单筛选是否有值
     * @param state
     */
    checkedValue(state) {
      return state.checkedValue;
    },
    /**
     * 清单筛选项是否可点击
     * @param state
     * @returns
     */
    disabledCheckbox(state) {
      return state.disabledCheckbox;
    },
    /**
     * 组价完,点击查看组价分析跳转投标模块打开搜索
     * @param state
     */
    selectStatus(state) {
      return state.selectStatus;
    },
    /**
     * 项目空间信息
     * @param state
     * @returns
     */
    projectSpaceDetail(state) {
      return state.projectSpaceDetail;
    },
    /**
     * 导出是否最小化
     * @param state
     * @returns
     */
    isExportMinimality(state) {
      return state.isExportMinimality;
    },
    getExportProcess(state) {
      if (state.exportProcess < 0) return 0;
      if (state.exportProcess > 100) return 100;
      return state.exportProcess;
    },
    isExportGenerateList(state) {
      return state.isExportGenerateList;
    },
    readOnly(state) {
      return state.readOnly;
    },
    loginVisible(state) {
      return state.loginVisible;
    },
    combinedSuccessVisible(state) {
      return state.combinedSuccessVisible;
    },
    artificialSummary(state) {
      return state.artificialSummary;
    },
    isStartCombined(state) {
      return state.isStartCombined;
    },
  },
};
export default project;
