



































































































import Vue from 'vue';
import { updatePassword } from '@/api/auth';

const formTailLayout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 14, offset: 4 },
};
export default Vue.extend({
  name: 'updatePassword',
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    let checkOldPasssword = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('请输入原密码'));
      } else {
        callback();
      }
    };
    let validateNewPassword = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入新密码'));
      } else if (value.split(' ').length > 1) {
        this.$data.checkError = true;
        this.$data.checkSuccess = false;
        callback(new Error('密码不可包含空格'));
      } else if (
        !/^(?=.*[0-9].*)(?=.*[A-Z].*)(?=.*[a-z].*).{6,20}$/.test(value)
      ) {
        this.$data.lengthError = true;
        this.$data.checkSuccess = false;
        callback(new Error('密码6-20字符，包含大小写字母和数字'));
      } else if (
        this.$data.ruleForm.oldPasssword === this.$data.ruleForm.newPassword
      ) {
        callback(new Error('新密码不能与原密码一致'));
      } else {
        this.$data.checkError = false;
        this.$data.lengthError = false;
        this.$data.checkSuccess = true;
        callback();
      }
    };
    let validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入重复密码'));
      } else if (value !== this.$data.ruleForm.newPassword) {
        callback(new Error('新密码与重复密码不一致,请重新输入'));
      } else {
        callback();
      }
    };
    return {
      ruleForm: {
        oldPasssword: '',
        newPassword: '',
        checkPass: '',
      },
      lengthError: false, // 密码长度判断
      checkError: false, // 大小写空格判断
      checkSuccess: false, // 校验是否成功
      rules: {
        oldPasssword: [{ validator: checkOldPasssword, trigger: 'blur' }],
        newPassword: [{ validator: validateNewPassword, trigger: 'blur' }],
        checkPass: [{ validator: validatePass2, trigger: 'blur' }],
      },
      layout: {
        labelCol: { span: 4 },
        wrapperCol: { span: 14 },
      },
      formTailLayout,
    };
  },
  methods: {
    cancel() {
      this.$emit('update:visible', false);
    },
    updatePassword() {
      (this.$refs.ruleForm as HTMLFormElement).validate((valid) => {
        if (!valid) return '';
        let apiData = {
          oldPasssword: this.ruleForm.oldPasssword,
          newPassword: this.ruleForm.newPassword,
        };
        updatePassword(apiData).then((res) => {
          console.log('res', res);
          if (res.status === 200 && res.result) {
            this.$message.success('密码修改成功');
            this.$emit('update:visible', false);
          }
        });
      });
    },
  },
});
