/*
 * @Descripttion:
 * @Author: sunchen
 * @Date: 2021-10-23 09:52:11
 * @LastEditors: sunchen
 * @LastEditTime: 2021-11-02 10:11:58
 */
import Vue from 'vue';
import Vuex from 'vuex';
Vue.use(Vuex);
const wsbaseUrl = process.env.VUE_APP_WS || 'ws://172.16.6.55:9101';
export default new Vuex.Store({
  state: {
    url: '',
    Socket: {} as WebSocket,
    task: null, //任务列表
    timeout: 30 * 1000, //30秒一次心跳
    timeoutObj: 0 as number,
  },
  getters: {
    onTaskList(state) {
      return state.task;
    },
  },
  mutations: {
    CLEAR_TASK(state) {
      state.task = null;
    },
    WEBSOCKET_INIT(state, url) {
      state.url = `${wsbaseUrl}${url}`;
      console.log('🚀 测试地址', state.url);
      state.Socket = new WebSocket(state.url);
      state.Socket.onopen = function () {
        console.log('连接成功！');
        //开启心跳
        state.timeoutObj && clearInterval(state.timeoutObj);
        state.timeoutObj = setInterval(() => {
          console.log('发送一个心跳');
          state.Socket.send('alive');
        }, state.timeout);
      };
      state.Socket.onmessage = function (callBack) {
        // console.log('ws接收！', callBack.data, state.task);
        if (callBack.data) {
          state.task = JSON.parse(callBack.data);
        }
        // if (callBack.data.status) {
        //   state.task = [];
        // }
      };
      state.Socket.onerror = function () {
        //错误
        console.log('ws错误!');
      };
      state.Socket.onclose = function () {
        //关闭
        console.log('ws关闭！', state.task);
        state.task = null;
        state.timeoutObj && clearInterval(state.timeoutObj);
      };
    },
    WEBSOCKET_SEND(state, p: string) {
      console.log('ws发送！', p);
      state.Socket.send(p);
    },
    WEBSOCKET_CLEAR(state) {
      console.log('🚀主动关闭', state);
      state.task = null;
      state.Socket && state.Socket.close && state.Socket.close();
      state.timeoutObj && clearInterval(state.timeoutObj);
    },
  },
  actions: {
    WEBSOCKET_INIT({ commit }, url) {
      commit('WEBSOCKET_INIT', url);
    },
    WEBSOCKET_SEND({ commit }, p) {
      commit('WEBSOCKET_SEND', p);
    },
  },
});
