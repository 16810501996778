/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/*
 * @Author: zhangguoliang
 * @Date: 2021-09-22 10:26:26
 * @LastEditors: zhangguoliang
 * @LastEditTime: 2023-02-24 17:11:21
 */
import './public-path';
require('@/components/global/index');
import '@/assets/style/commons.less';

import Vue from 'vue';
import router from './router';
import store from './store';
import {
  Icon,
  Button,
  message,
  FormModel,
  Input,
  Menu,
  Layout,
  Tabs,
  Select,
  Radio,
  Switch,
  Checkbox,
  DatePicker,
  Upload,
  Table,
  Divider,
  Tag,
  Dropdown,
  Row,
  Col,
  Modal,
  Spin,
  Tree,
  InputNumber,
  Pagination,
  ConfigProvider,
  Popover,
  Tooltip,
  Popconfirm,
  Empty,
  Drawer,
  Collapse,
} from 'ant-design-vue';
import '@/permission';
import { readFromStorage, save2Storage } from '@/utils/store2storage';
import App from './App.vue';
import websocket from '@/store/websocketStore';
import Cookies from 'js-cookie';
import { zbtProjectViewMerge } from '@/api/projectDetail';

message.config({
  maxCount: 1,
  getContainer: () => document.getElementById('ysf_body') as HTMLElement,
});

Spin.setDefaultIndicator({
  indicator: (h) => {
    return h('img', {
      style: {
        display: 'block',
        width: '140px',
        height: 'auto',
      },
      attrs: {
        src: 'https://hzjt-ui-publiclib.oss-cn-beijing.aliyuncs.com/YZJXPT/logo%20loading.gif',
      },
    });
  },
});
Vue.config.productionTip = false;

const IconFont = Icon.createFromIconfontCN({
  scriptUrl: '//at.alicdn.com/t/c/font_2841474_sd5rbkarpd.js',
});

window.addEventListener('beforeunload', save2Storage);

readFromStorage();

Vue.component('IconFont', IconFont);

Vue.use(Button)
  .use(FormModel)
  .use(Input)
  .use(Layout)
  .use(Tabs)
  .use(Select)
  .use(Radio)
  .use(Menu)
  .use(Switch)
  .use(Checkbox)
  .use(DatePicker)
  .use(Upload)
  .use(Table)
  .use(Divider)
  .use(Tag)
  .use(Dropdown)
  .use(Row)
  .use(Col)
  .use(Modal)
  .use(Spin)
  .use(Tree)
  .use(InputNumber)
  .use(Icon)
  .use(Pagination)
  .use(ConfigProvider)
  .use(Tooltip)
  .use(Popover)
  .use(Empty)
  .use(Popconfirm)
  .use(Collapse)
  .use(Drawer);
Vue.prototype.$websocket = websocket;
Vue.prototype.$message = message;
Vue.prototype.$logout = (type: number) => {
  location.href = `${process.env.VUE_APP_LOGIN_URL + type}`;
};

let instance: any = null;

function render(props: any = {}) {
  const { container } = props;
  instance = new Vue({
    router,
    store,
    render: (h) => h(App),
  }).$mount(container ? container.querySelector('#app') : '#app');
}

if (!window.__POWERED_BY_QIANKUN__) {
  render();
}

export async function bootstrap() {
  console.log('[vue] vue app bootstraped');
}

export async function mount(props) {
  props.setLoading && props.setLoading(false);
  props.onGlobalStateChange(async (state: any) => {
    const { logout, ggId = '', isVisitor = 0, agencyCode } = state;
    const cookieGId = Cookies.get('ggId');
    console.log(state);
    Vue.prototype.$logout = logout;
    const { portalState } = store.getters;
    const token = Cookies.get('token')?.replace(/bearer /, '');
    if (
      token &&
      portalState.agencyCode &&
      agencyCode &&
      portalState.agencyCode !== agencyCode
    ) {
      store.commit('SET_TOKEN', '');
      store.commit('SET_USER_INFO', null);
      await store.dispatch('login', token);
      await store.dispatch('getUserInfo');
      router
        .replace({
          path: '/',
          query: {
            id: Date.now() + '',
          },
        })
        .catch((err) => {
          console.log(err);
        });
    }
    store.commit('SET_PORTAL_STATE', state);
    const t = setInterval(() => {
      if (store.getters.token) {
        clearInterval(t);
        if (ggId || (isVisitor && cookieGId)) {
          sessionStorage.setItem('ggId', ggId);
          sessionStorage.setItem('isVisitor', isVisitor);
          zbtProjectViewMerge({ ggId: ggId || cookieGId })
            .then((res) => {
              if (res.status === 200) {
                router.push('/ysf/project');
              }
            })
            .finally(() => {
              props.setGlobalState({
                mobile: '',
                ggId: '',
                isVisitor: 0,
              });
            });
        }
      }
    }, 100);
  }, true);
  Vue.prototype.$setGlobalState = props.setGlobalState;
  render(props);
}

export async function unmount() {
  instance.$destroy();
  instance.$el.innerHTML = '';
  instance = null;
}
