








































































































import Vue from 'vue';
import UpdatePassword from '@/layout/updatePassword.vue';
import { loginCount } from '@/api/auth';
import OpenedRoutes from '@/layout/tabs.vue';
import { mapGetters } from 'vuex';
export default Vue.extend({
  components: { UpdatePassword, OpenedRoutes },
  props: {
    collapsed: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      visible: false,
      loginNum: 0,
      popoverVisible: false,
    };
  },
  computed: {
    ...mapGetters(['userInfo', 'portalState']),
    isEnterprise(): boolean {
      if (!this.userInfo) return false;
      const { userType } = this.userInfo;
      return userType === 'Enterprise';
    },
    avatarUrl(): string {
      if (this.userInfo.userType === 'Enterprise') {
        return 'https://hzjt-ui-publiclib.oss-cn-beijing.aliyuncs.com/YYFUPT/enterprise.png';
      } else if (this.userInfo.userType === 'Normal') {
        return 'https://hzjt-ui-publiclib.oss-cn-beijing.aliyuncs.com/YYFUPT/Not%20certified.png';
      }
      return 'https://hzjt-ui-publiclib.oss-cn-beijing.aliyuncs.com/YYFUPT/attestation.png';
    },
  },
  created() {
    this.loginNum = Number(sessionStorage.getItem('loginNum')) || 0;
    if (Number(this.loginNum) < 2) {
      this.loginCount();
    }
  },
  methods: {
    changeCollapse() {
      this.$emit('changeCollapse', !this.collapsed);
    },
    logout() {
      this.$store.dispatch('logout', 1);
    },
    updatePassword() {
      this.popoverVisible = false;
      this.visible = true;
    },
    toChangeIdentity() {
      this.$setGlobalState({ redirect: '/sub1' });
      history.pushState(null, '', '/identity');
    },
    loginCount() {
      loginCount().then((res) => {
        console.log('res', res);
        if (res.status === 200 && res.result === 1) {
          this.loginNum++;
          sessionStorage.setItem('loginNum', String(this.loginNum));
          if (this.loginNum < 2) {
            this.visible = true;
          }
        }
      });
    },
  },
});
