













import Vue from 'vue';
import zh_CN from 'ant-design-vue/lib/locale-provider/zh_CN';
import moment from 'moment';
import 'moment/locale/zh-cn';
moment.locale('zh-cn');
export default Vue.extend({
  data() {
    return {
      zh_CN,
    };
  },
  created() {
    // this.initWebsocket();
  },
  methods: {
    getPopupContainer() {
      return document.getElementById('ysf_body');
    },
  },
});
