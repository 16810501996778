/*
 * @Descripttion:
 * @Author: renmingming
 * @Date: 2021-10-19 14:07:47
 * @LastEditors: zhangguoliang
 * @LastEditTime: 2023-03-08 16:00:25
 */

export type price = number | null | undefined | string;

export type numberOrString = number | string;

/**
 * 项目详情接口返回部分类型
 */
export interface ProjectDetailType {
  constructName: string;
  total: number;
  extend1: number;
  sbf: number;
  ssCity: string;
}

/**
 * 项目树接口返回数据类型
 */
export interface ProjectTree {
  sequenceNbr: string | null;
  bidSequenceNbr: string;
  name: string;
  type: string;
  children: ProjectTree[];
  parentId: string;
  xmType: null | string;
  isBlank?: boolean;
  ifChildrenMissing?: boolean;
  ifDeReplaced?: string | null;
}

/**
 * 工程项目总价表接口返回类型
 */
export interface TotalPriceItem {
  dispNo?: string;
  sortNo?: number;
  projectName: string;
  code?: string;
  total: price;
  gfee: price;
  safeFee: price;
  sbf?: price;
  sequenceNbr?: string;
}

/**
 * 单项工程费汇总
 */
export interface SummaryItem {
  dispNo?: string;
  projectName: string;
  total: price;
  gfee: price;
  safeFee: price;
  sortNo?: number;
  upName?: string;
  uptotal?: price;
  sbf?: number;
  udcUnitProjectModels?: SummaryItem[];
}

type StringOrNull = string | null;

/**
 *报表树
 */
export interface ReportTreeItem {
  sequenceNbr: string;
  name: string;
  type: StringOrNull;
  children: ReportTreeItem[];
  parentId: StringOrNull;
  xmType: StringOrNull;
  reportName?: string;
  key?: string;
  itemCategory?: StringOrNull;
  singleProjectSequenceNbr?: StringOrNull;
  scopedSlots?: { title: string };
  storageUrl?: string;
  shuBanUrl: string;
  excelUrl: string;
  hengBanUrl: string;
  storageFormat: string;
  active?: boolean;
}

/**
 * 单位工程费汇总
 */
export interface SingleSummaryType {
  dispNo: string;
  fbName: string;
  formulaLabel?: string;
  rate?: string;
  total?: string | undefined;
  rfee?: numberOrString;
  cfee?: numberOrString;
  jfee?: numberOrString;
  isMultiple?: boolean | undefined;
  code: string;
  upName?: string;
  fl?: string | number;
  description?: string;
}

/**
 * 组价分析
 */
export interface GetZjCountType {
  jspp: number;
  jzpp: number;
  neverZj: number;
  total: number;
}

/**
 * 刷新左侧树时的来源
 * combinedPrice 组价之后刷新
 * replace 替换之后刷新不更改当前节点
 * adjustmentOrAgainMerge 计价或调价之后更改是否可计价状态
 */
export enum RefreshTreeFrom {
  COMBINED_PRICE = 'combinedPrice',
  REPLACE = 'replace',
  ADJUSTMENT_MERGE_SUCCESS = 'adjustmentOrAgainMerge',
}

export interface projectSpaceDetailType {
  importNumber: number;
  exportNumber: number;
  projectSpaceStatus: number;
}

export interface CheckErrorsType {
  errorPlace: string;
  errorNo: string;
  errorInfo: string;
}

export interface mergeInitCheckType {
  isRight: boolean;
  errorTotal: number;
  errorPages: number;
  errors: CheckErrorsType[];
}

export enum ExportApproveStatus {
  WAIT = 0, // 审核中
  SUCCESS = 1, // 成功
  FAIL = 2, // 驳回
  NOT = 3, // 未申请
  CANCEL = 8, // 已作废
}

export interface ExportRecordsType {
  constructId: string;
  constructProjectName: string;
  constructProjectMd5: string;
  fileType: number;
  ip: string;
  exportSource: number;
  exportTime: string;
  exportReportUrl: ReportTreeItem;
}
