



























import Vue from 'vue';

export default Vue.extend({
  name: 'SubMenu',
  props: {
    menuInfo: {
      type: Object,
      default: () => ({}),
    },
  },
});
