/*
 * @Descripttion:
 * @Author: sunchen
 * @Date: 2021-06-15 15:44:23
 * @LastEditors: sunchen
 * @LastEditTime: 2021-10-26 14:33:19
 */
import request from '@/utils/request';
import { BaseResponse } from '@/interface';

const http = {
  /**
   * @description: get请求
   * @param {*}
   * @return {*}
   */
  get: <T>(url: string, params = {}): Promise<BaseResponse<T>> => {
    return request({
      url: `${url}`,
      method: 'get',
      ...params,
      params: {
        ...params,
      },
    });
  },
  /**
   * POST请求
   * @param {String} url - 接口地址
   */
  post: <T>(url: string, params = {}): Promise<BaseResponse<T>> => {
    return request({
      ...params,
      url: `${url}`,
      method: 'post',
      data: params,
    });
  },
  /**
   * put请求
   * @param {String} url - 接口地址
   */
  put: <T>(url: string, params = {}): Promise<BaseResponse<T>> => {
    return request({
      url: `${url}`,
      method: 'put',
      data: params,
    });
  },
  /**
   * @description: delete请求
   * @param {String} url
   * @return {*}
   */
  delete: <T>(url: string, params = {}): Promise<BaseResponse<T>> => {
    return request({
      url: `${url}`,
      method: 'delete',
      data: params,
    });
  },
};

export default http;
