/*
 * @Description: 全局路由守卫
 * @Author: zhangguoliang
 * @Date: 2021-08-03 17:02:51
 * @LastEditors: zhangguoliang
 * @LastEditTime: 2023-02-23 14:10:23
 */

import router, { constantRoutes } from '@/router';
import store from '@/store';
import NProgress from 'nprogress';
import 'nprogress/nprogress.css';
import { BaseResponse, Tag } from './interface';
import isEmpty from 'lodash/isEmpty';
import { loginByToken } from '@/api/auth';
import { sys_dict } from '@/api/common';
import Cookies from 'js-cookie';

NProgress.configure({ showSpinner: false });

const paths = constantRoutes.map((item) => item.path);

function addTags(tag: Tag) {
  const { tags }: { tags: Tag[] } = store.getters;
  const paths: string[] = tags.map((tag) => tag.path);
  if (!paths.includes(tag.path)) {
    store.commit('SET_TAGS', [...tags, tag]);
  } else {
    store.commit(
      'SET_TAGS',
      tags.map((t) => ({
        ...t,
        query: t.path === tag.path ? tag.query : t.query,
      }))
    );
  }
}

router.beforeEach(async (to, from, next) => {
  NProgress.start();
  if (!/^\/ysf.*$/.test(to.path)) {
    return;
  }
  const whiteList = ['/ysf/whiteList/detailIndex'];
  if (whiteList.includes(to.path)) {
    Cookies.remove('token');
    store.commit('SET_TOKEN', '');
    next();
    return;
  }
  const title = to.meta?.title || '云算房';
  document.title = title;
  const token = Cookies.get('token')?.replace(/bearer /, '');
  if (token && !store.getters.token) {
    await store.dispatch('login', token);
    next(to.fullPath);
    return;
  }

  if (!store.getters.token) {
    store.dispatch('logout', 2);
    next(false);
    return;
  } else if (isEmpty(store.getters.menus)) {
    await store.dispatch('menus');
    next(to.fullPath); // 重置路由
  } else {
    if (isEmpty(store.getters.userInfo)) {
      await store.dispatch('getUserInfo');
    }
    next();
  }
});

router.afterEach((to) => {
  if (to.name && !paths.includes(to.path)) {
    addTags({ name: to.meta?.title as string, path: to.path, query: to.query });
  }
  NProgress.done();
});
