/*
 * @Description: save store to storage or read from storage.
 * @Author: zhangguoliang
 * @Date: 2021-08-03 16:35:23
 * @LastEditors: renmingming
 * @LastEditTime: 2022-09-23 14:19:33
 */
import store from '@/store';

const props = ['tags', 'dictList', 'currentMajorInfo'];

export function readFromStorage(): void {
  props.forEach((prop) => {
    const storage = sessionStorage.getItem(prop);
    if (!storage) return;
    let parse;
    try {
      parse = JSON.parse(storage);
    } catch (e) {
      parse = storage;
    }
    store.commit('SET_' + prop.toUpperCase(), parse);
  });
}

export function save2Storage(): void {
  props.forEach((prop) => {
    const value = store.getters[prop];
    if (!value) return;
    sessionStorage.setItem(prop, JSON.stringify(value));
  });
}
