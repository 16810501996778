






















import Vue from 'vue';

export default Vue.extend({
  name: 'Dialog',
  props: {
    visible: {
      type: Boolean,
      default: undefined,
    },
  },
  data() {
    return {};
  },
  computed: {},
  methods: {
    close() {
      this.$emit('update:visible', false);
    },
  },
});
