/*
 * @Author: zhangguoliang
 * @Date: 2021-12-10 09:45:48
 * @LastEditors: zhangguoliang
 * @LastEditTime: 2021-12-20 10:29:42
 */

import request from '@/utils/request';
import Qs from 'qs';

/**
 * 云造价登录
 * @param token
 */
export function loginByToken(token: string): Promise<any> {
  return request({
    url: '/auth/oauth/token',
    method: 'post',
    headers: {
      Authorization: 'Basic cml2ZXQ6eGpsaWRvbmcxMjM=',
    },
    data: Qs.stringify({
      token,
      grant_type: 'xilidata',
      scope: 'all',
    }),
  });
}


// 修改密码
export function updatePassword(data: any): any {
  return request({
    url: `/cocc-mtds-resource/1/user/updatePassword`,
    method: 'post',
    data,
  });
}

// 查询登录次数
export function loginCount(): any {
  return request({
    url: `/cocc-mtds-resource/1/user/count/login`,
    method: 'get',
  });
}
