/*
 * @Description: common
 * @Author: zhangguoliang
 * @Date: 2021-08-12 17:56:40
 * @LastEditors: zhangguoliang
 * @LastEditTime: 2023-02-23 14:08:27
 */

import { Tag } from '@/interface';
import { Module } from 'vuex';
import RootState, { CommonState } from './interface';

const common: Module<CommonState, RootState> = {
  state: {
    tags: [],
    dictList: [],
    portalState: {},
  },
  mutations: {
    SET_TAGS(state, tags: Tag[]) {
      state.tags = tags;
    },
    SET_DICTLIST(state, dictList: string[]) {
      state.dictList = dictList;
    },
    SET_PORTAL_STATE(state, portalState: Record<string, any>) {
      state.portalState = portalState;
    },
  },
  getters: {
    tags(state) {
      return state.tags;
    },
    dictList(state) {
      return state.dictList;
    },
    portalState(state) {
      return state.portalState;
    },
  },
};

export default common;
