/*
 * @Description: user state
 * @Author: zhangguoliang
 * @Date: 2021-08-03 09:47:14
 * @LastEditors: zhangguoliang
 * @LastEditTime: 2023-02-23 14:17:40
 */

import router, { errorRoutes } from '@/router';
import isEmpty from 'lodash/isEmpty';
import { Module } from 'vuex';
import { MenuItem } from '@/interface';
import RootState, { UserState } from './interface';
import { agencyInfo } from '@/api';
import Vue from 'vue';
import { loginByToken } from '@/api/auth';
import { sys_dict } from '@/api/common';

/**
 * @description: 获取所有的路由列表
 * @param {any} menus
 * @return {*}
 */
function flatMapRoutes(menus: MenuItem[]): MenuItem[] {
  return menus
    ?.map((menu) => {
      if (isEmpty(menu.children)) {
        return menu;
      }
      // 为了刷新页面菜单栏定位到刷新前状态, 属性添加父菜单path
      return flatMapRoutes(
        menu.children?.map((item) => ({
          ...item,
          subMenuKey: [...(menu.subMenuKey || []), menu.path],
        })) || []
      );
    })
    .flat(5);
}

/**
 * @description: 生成动态路由
 * @param {MenuItem} routes
 * @return {*}
 */
function genRoutes(routes: MenuItem[]) {
  routes.forEach(({ path, location, ...meta }) => {
    const p = path.replace(/^\//, '');
    if (location) {
      const local = location.replace(/^\//, '');
      router.addRoute('root', {
        path: p,
        name: p,
        component: () => import(`@/views/${local}.vue`),
        meta,
      });
    }
  });
  errorRoutes.forEach((route) => {
    router.addRoute(route);
  });
}

/**
 * @description: 过滤菜单，隐藏hidden: true的项
 * @param {*}
 * @return {*}
 */
function filterMenus(menus: MenuItem[]): MenuItem[] {
  return menus
    .filter((menu) => !menu.hidden)
    .map((menu) => ({
      ...menu,
      children: filterMenus(menu.children || []),
    }));
}

const menus = [
  {
    path: '/ysf/home',
    location: 'home',
    icon: 'icon-gongzuotai-moren',
    title: '工作台',
  },
  {
    path: '/ysf/project',
    location: 'projects/list/project-space-list',
    icon: 'icon-gongchengxiangmuguanli-moren',
    title: '工程项目',
  },
  {
    path: '/ysf/enterpriseLibrary',
    icon: 'icon-qiyeguanliku',
    title: '企业管理库',
    children: [
      {
        path: '/ysf/materialMachineLibrary/index',
        location: 'enterpriseLibrary/materialMachineLibrary/index',
        title: '材机库',
      },
      {
        path: '/ysf/costQuotaLibrary/list',
        location: 'enterpriseLibrary/costQuotaLibrary/index',
        title: '企业成本组价管理',
        keepAlive: true,
      },
      {
        path: '/ysf/costQuotaLibrary/detail',
        name: 'costQuotaLibraryDetail',
        location: 'enterpriseLibrary/costQuotaLibrary/detail/index',
        title: '企业成本库详情',
        hidden: true,
      },
      {
        path: '/ysf/materialMachineLibrary/detail',
        name: 'materialMachineDetail',
        location: 'enterpriseLibrary/materialMachineLibrary/detail/index',
        title: '材机库详情',
        hidden: true,
      },
      {
        path: '/ysf/costQuotaLibrary/add',
        name: 'addCostQuotaLibrary',
        location: 'enterpriseLibrary/costQuotaLibrary/costLibrary/add/index',
        title: '添加企业成本组价',
        hidden: true,
      },
      {
        path: '/ysf/costQuotaLibrary/quotaAdjustment',
        name: 'quotaAdjustment',
        location:
          'enterpriseLibrary/costQuotaLibrary/costLibrary/quotaAdjustment',
        title: '定额明细调整',
        hidden: true,
      },
    ],
  },
  {
    path: '/ysf/helpCenter',
    location: 'helpCenter/index',
    icon: 'icon-bangzhuicon',
    title: '帮助中心',
    children: [
      {
        path: '/ysf/video',
        location: 'helpCenter/index',
        title: '视频学习',
      },
      {
        path: '/ysf/userHandbook',
        location: 'helpCenter/userHandbook',
        title: '操作手册',
      },
    ],
  },
];

const user: Module<UserState, RootState> = {
  state: {
    token: '',
    menus: [],
    userInfo: null,
  },
  mutations: {
    SET_TOKEN(state, token: string) {
      state.token = token;
    },
    SET_MENUS(state, menus: MenuItem[]) {
      state.menus = menus;
    },
    SET_USER_INFO(state, userInfo) {
      state.userInfo = userInfo;
    },
  },
  actions: {
    async login({ commit }, token: string) {
      const res = await loginByToken(token);
      commit('SET_TOKEN', res.access_token);
      const dicts = await sys_dict();
      sessionStorage.setItem('dictList', JSON.stringify(dicts.result));
      commit('SET_DICTLIST', dicts.result);
      return res;
    },
    logout({ commit }, type) {
      commit('SET_TOKEN', '');
      commit('SET_MENUS', []);
      commit('SET_TAGS', []);
      sessionStorage.clear();
      // history.pushState(null, '', `/${type}`);
      let instance: any = new Vue();
      instance.$logout(type);
      instance = null;
    },
    /**
     * @description: 菜单生成路由规则，如果menuItem有children且不为空，获取children里的项作为路由，忽略其他属性
     * @param {*} param1
     * @return {*}
     */
    menus({ commit }) {
      genRoutes(flatMapRoutes(menus));
      commit('SET_MENUS', filterMenus(menus));
      return Promise.resolve(menus);
    },
    async getUserInfo({ commit }) {
      const res = await agencyInfo();
      if (res.status === 200) {
        commit('SET_USER_INFO', res.result);
      }
      return res.result;
    },
  },
  getters: {
    token(state) {
      return state.token;
    },
    menus(state) {
      return state.menus;
    },
    userInfo(state) {
      return state.userInfo;
    },
    isQuotaLibraryIdentify(state) {
      return state.userInfo?.userType === 'Enterprise'
        ? 1
        : state.userInfo?.userType !== 'Enterprise' &&
          state.userInfo?.enterprisePassed
        ? 2
        : 0;
    },
  },
};

export default user;
