/*
 * @Descripttion: 企业成本库相关store
 * @Author: liuxia
 * @Date: 2022-06-15 16:08:16
 * @LastEditors: renmingming
 * @LastEditTime: 2022-08-31 14:16:57
 */
import { Module } from 'vuex';
import RootState, { CostQuota } from './interface';
import Vue from 'vue';

const project: Module<CostQuota, RootState> = {
  state: {
    currentMajorInfo: null,
  },
  mutations: {
    SET_CURRENTMAJORINFO(state, treeInfo: CostQuota['currentMajorInfo']) {
      state.currentMajorInfo = JSON.parse(JSON.stringify(treeInfo));
    },
  },
  actions: {},
  getters: {
    currentMajorInfo(state) {
      return state.currentMajorInfo;
    },
  },
};
export default project;
