/*
 * @Author: zhangguoliang
 * @Date: 2021-09-22 10:26:26
 * @LastEditors: renmingming
 * @LastEditTime: 2022-08-19 14:17:17
 */
import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import Layout from '@/layout/index.vue';

Vue.use(VueRouter);

const routes: RouteConfig[] = [
  {
    path: '/',
    name: 'root',
    redirect: '/ysf/home',
    component: Layout,
    children: [
      {
        path: 'ysf/home',
        name: 'home',
        component: () =>
          import(/* webpackChunkName: "home" */ '@/views/Home.vue'),
        meta: { title: '工作台' },
      },
    ],
  },
];

// 固定路由，不会被添加到tags中
export const constantRoutes: RouteConfig[] = [
  {
    path: '/ysf/project/detail',
    name: 'projectDetail',
    component: () => import('@/views/projects/detail/index.vue'),
  },
  {
    path: '/ysf/whiteList/detailIndex',
    name: 'detailIndex',
    component: () => import('@/views/projects/whiteList/detailIndex.vue'),
  },
  {
    path: '/ysf/projects/proList/list',
    name: '切换项目',
    component: () => import('@/views/projects/proList/list.vue'),
  },
  {
    path: '/ysf/projects/proList/guide',
    name: '步骤弹框',
    component: () => import('@/views/projects/proList/guide.vue'),
  },
];

export const errorRoutes: RouteConfig[] = [
  {
    path: '/:catchAll(.*)',
    component: () => import('@/views/error/404.vue'),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: window.__POWERED_BY_QIANKUN__ ? '/sub1/' : process.env.BASE_URL,
  routes: [...routes, ...constantRoutes],
});

export default router;
